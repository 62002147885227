<template>
  <div
    class="fixed top-0 right-0 top-app-header mt-2 mr-3 h-screen pointer-events-none z-50 w-80 h-18"
  >
    <transition name="slide-down" mode="out-in">
      <div
        class="relative ring-1 ring-black ring-opacity-5 shadow-lg flex rounded bg-white p-4 pointer-events-auto cursor-pointer text-sm"
        @click="hidden = true"
        v-if="!hidden && loaded"
      >
        <slot></slot>
        <CloseIcon
          :class="{ primaryColor: !primaryColor }"
          class="absolute top-0 right-0 m-4 close-icon w-4"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import CloseIcon from '@/assets/icon_close.svg';

export default {
  name: 'Toast',
  components: {
    CloseIcon,
  },
  props: {
    /**
     * Message to display in the Toast
     */
    message: {
      type: String,
    },
    /**
     * Duration to display the toast
     */
    duration: {
      type: Number,
      default: () => 3000,
    },
    primaryColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hidden: false,
      loaded: false,
      timer: '',
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded = true;
      this.timer = setInterval(() => {
        this.hidden = true;
      }, this.duration);
    });
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  &.primaryColor:hover {
    path {
      @apply stroke-primary;
    }
  }

  path {
    stroke: #aaacaf;
  }
}

.slide-down-enter-active {
  animation: slide-down 0.2s;
}

.slide-down-leave-active {
  animation: slide-down 0.2s reverse;
}

@keyframes slide-down {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
